import { PageProps } from 'gatsby';
import { MediaData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/media.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceMediaUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { mediaId, parentCollectionName, parentId },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        displayView={false}
        docId={mediaId}
        model={
          new MediaData({
            params,
            parentCollectionName,
            parentId,
            espaceId: espace.id,
          })
        }
        parentCollectionName={parentCollectionName}
        parentId={parentId}
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);
export default requireEspace(PageEspaceMediaUpdate);
